<template>
    <el-tabs v-model="activeName" class="tabs" @tab-click="handleClick">
        <el-tab-pane label="佣金确认" name="first">
            <el-card>
                <template #header>
                    <div class="headChange">
                        <div style="display: flex;gap: 20px;align-items: center;justify-content: center;flex-wrap: wrap;">
                          <el-form-item label="分润月份:" style="margin-bottom: 0">
                            <el-date-picker v-model="form.month" type="month" placeholder="请选择"
                                            value-format="YYYYMM" />
                          </el-form-item>
                          <el-form-item label="一级代理:" style="margin-bottom: 0">
                            <el-select v-model="form.agent_id" filterable clearable>
                              <el-option v-for="item in agentList" :key="item.value" :label="item.label" :value="item.value" />
                            </el-select>
                          </el-form-item>
                          <el-button type="primary" @click="refreshTab('first')">查询</el-button>
                          <el-button type="primary" :icon="Plus" plain @click="openDialog(false)">新增确认表</el-button>
                        </div>
                    </div>
                </template>

                <el-table :data="tableData" v-loading="isloading" stripe style="width: 100%" :row-style="rowStyle">
                    <el-table-column prop="month" label="分润月份" min-width="120" />
                    <el-table-column prop="title" label="佣金项目" />
                    <el-table-column prop="should_checkout_amount" label="应结佣金" />
                    <el-table-column prop="agent_name" label="一级代理" />
                    <el-table-column prop="is_confirmed_text" label="确认状态">
                        <template #default="scope">
                            <div :class="getAuditStatusClass(scope.row.is_confirmed)">
                                {{ scope.row.is_confirmed_text }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="is_applied_text" label="申请状态">
                        <template #default="scope">
                            <div :class="getAuditStatusClass(scope.row.is_applied)">
                                {{ scope.row.is_applied_text }}
                            </div>
                        </template>
                    </el-table-column>
                  <el-table-column prop="audit_status_text" label="审核状态">
                    <template #default="scope">
                      <div :class="getAuditStatusClass(scope.row.audit_record?.audit_status)">
                        {{ scope.row.audit_record?.audit_status_text || '-' }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="settled_at" label="结算时间" width="220" show-overflow-tooltip>
                    <template #default="scope">
                        {{ scope.row.audit_record?.settled_at || '-' }}
                    </template>
                  </el-table-column>
                    <el-table-column fixed="right" label="操作" width="260">
                        <template #default="scope">
                            <el-button type="primary" size="small" @click="openDialog(true, scope.row)">
                                编辑
                            </el-button>
                          <el-button size="small"
                                     @click="downloadFile(scope.row.detail_sheet_url)">下载表格</el-button>
                            <el-button type="danger" size="small" @click="deleteRecord(scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="pagination" style="display: flex;justify-content: flex-end;">
                    <el-pagination background layout="total,prev, pager, next, jumper, slot" :total="pageinfo.total"
                        @current-change="pageChange" :page-size="pageinfo.perPage" :current-page="pageinfo.currentPage"
                        :hide-on-single-page="false">
                    </el-pagination>
                </div>
            </el-card>

            <el-dialog v-model="confirmFormVisible" :title="dialogTitle" width="560px" draggable
                :before-close="handleDialogClose">
                <el-form :model="confirmForm" :rules="rules" ref="formRef" class="custom-form" label-width="120px">
                    <el-form-item label="一级代理：" prop="agent_id" :label-width="formLabelWidth">
                        <el-select v-model="confirmForm.agent_id" placeholder="请选择" filterable clearable
                            style="width: 360px;">
                            <el-option v-for="item in agentList" :key="item.value" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分润月份：" prop="month" :label-width="formLabelWidth">
                        <el-date-picker v-model="confirmForm.month" type="month" placeholder="请选择年月"
                            value-format="YYYYMM" style="width: 360px;" />
                    </el-form-item>
                    <el-form-item label="佣金项目：" prop="title" :label-width="formLabelWidth">
                        <el-input v-model="confirmForm.title" autocomplete="off" clearable />
                    </el-form-item>
                    <el-form-item label="应结佣金：" prop="should_checkout_amount" :label-width="formLabelWidth">
                        <el-input v-model="confirmForm.should_checkout_amount" autocomplete="off" clearable />
                    </el-form-item>
                    <el-form-item label="佣金明细表：" prop="detail_sheet" :label-width="formLabelWidth" style="min-height: 240px;">
                        <el-upload class="upload-demo" drag :http-request="uploadFile" style="width: 240px;"
                                   accept=".xls,.xlsx,.csv"
                                   v-if="!confirmForm.detail_sheet">
                            <el-icon class="el-icon--upload">
                                <UploadFilled />
                            </el-icon>
                            <div class="el-upload__text">
                                拖拽文件或<em>点击上传</em>
                            </div>
                        </el-upload>
                        <div v-else style="color: #67C23A;display: flex;flex-direction: column ;align-items: flex-start;width: 240px;height: 100%;gap: 4px;">
                          <div style="display: flex;justify-content: space-between;font-size: 12px;align-items: center;width: 100%;">
                            <span>上传成功</span>
                            <el-button size="small" plain type="warning" :icon="CloseBold" @click="clearFile">重新上传</el-button>
                          </div>
                          <div>
                            <a :href="confirmForm.detail_sheet_url" target="_blank">{{ confirmForm.detail_sheet_url.split('/').pop() }}</a>
                          </div>
                        </div>
                    </el-form-item>
                </el-form>
                <div style="display: flex; justify-content: center;">
                    <el-button @click="closeDialog" style="width: 80px;">取消</el-button>
                    <el-button type="primary" @click="submitForm" style="width: 80px;">确认</el-button>
                </div>
            </el-dialog>
        </el-tab-pane>

        <el-tab-pane name="second">
            <template #label>
                <span>佣金审核</span>
                <el-badge :value="todoCount" v-if="todoCount != 0">
                </el-badge>
            </template>
            <el-card>
                <template #header>
                    <div class="headChange">
                      <div style="display: flex;gap: 20px;align-items: center;justify-content: center;flex-wrap: wrap;">
                        <el-form-item label="一级代理:" style="margin-bottom: 0">
                          <el-select v-model="form2.agent_id" filterable clearable>
                            <el-option v-for="item in agentList" :key="item.value" :label="item.label" :value="item.value" />
                          </el-select>
                        </el-form-item>
                        <el-button type="primary" @click="refreshTab('second')">查询</el-button>
                      </div>
                    </div>
                </template>

                <el-table :data="auditTableData" v-loading="isloading" stripe style="width: 100%" :row-style="rowStyle">
                    <el-table-column label="分润月份">
                        <template #default="scope">
                            <div v-for="(item, index) in scope.row.confirm_records" :key="index">
                                <div>{{ item.month }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="佣金项目">
                        <template #default="scope">
                            <div v-for="(item, index) in scope.row.confirm_records" :key="index">
                                <div>{{ item.title }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="should_checkout_amount" label="应结佣金" />
                    <el-table-column prop="agent_name" label="一级代理">
                        <template #default="scope">
                            <div>{{ scope.row.confirm_records[0].agent_name }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="audit_status_text" label="审核状态">
                        <template #default="scope">
                            <div :class="getAuditStatusClass(scope.row.audit_status)">
                                {{ scope.row.audit_status_text }}
                            </div>
                        </template>
                    </el-table-column>
                  <el-table-column prop="settled_at" label="结算时间" width="220" show-overflow-tooltip>
                    <template #default="scope">
                      {{ scope.row.settled_at || '-' }}
                    </template>
                  </el-table-column>
                    <el-table-column fixed="right" label="操作" width="200">
                        <template #default="scope">
                            <el-button :type="scope.row.audit_status > 0 ? 'plain' : 'danger'" @click="auditCommission(scope.row)">
                              {{ scope.row.audit_status > 0 ? '修改审核' : '审核' }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="pagination" style="display: flex; align-items: center; justify-content: flex-end;">
                    <!-- <el-icon color="#73767a" style="margin-right: 10px;"><Refresh /></el-icon> -->

                    <el-pagination background layout="total,prev, pager, next, jumper, slot" :total="pageinfo.total"
                        @current-change="pageChange" :page-size="pageinfo.perPage" :current-page="pageinfo.currentPage"
                        :hide-on-single-page="false">
                    </el-pagination>
                </div>

            </el-card>

            <el-dialog v-model="auditFormVisible" title="审核佣金申请" width="560px" draggable>
                <el-form :model="auditForm" class="custom-form" label-width="120px">
                    <el-form-item label="一级代理：" prop="agent_name" :label-width="formLabelWidth">
                        {{ auditForm.agent_name }}
                    </el-form-item>
                    <el-form-item label="分润月份：" prop="months" :label-width="formLabelWidth">
                        {{ auditForm.months.join(', ') }}
                    </el-form-item>
                    <el-form-item label="佣金项目：" prop="titles" :label-width="formLabelWidth">
                        {{ auditForm.titles.join(', ') }}
                    </el-form-item>
                    <el-form-item label="应结佣金：" prop="should_checkout_amount" :label-width="formLabelWidth">
                        {{ auditForm.should_checkout_amount }}
                    </el-form-item>
                    <el-form-item label="电子发票：" :label-width="formLabelWidth">
                        <template v-if="isImageUrl(auditForm.imgUrl)">
                            <el-image style="width: 100px; height: 100px" :src="auditForm.imgUrl"
                                :preview-src-list="previewSrcList" fit="cover" />
                        </template>
                        <template v-else>
                            <a :href="auditForm.imgUrl" target="_blank">下载文件</a>
                        </template>
                    </el-form-item>
                    <el-form-item label="审批结果：" :label-width="formLabelWidth">
                        <el-radio-group v-model="auditForm.audit_status">
<!--                            <el-radio :label="0" size="large">待审核</el-radio>-->
                            <el-radio :label="1" size="large">通过</el-radio>
                            <el-radio :label="2" size="large">拒绝</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="备注：" :label-width="formLabelWidth">
                        <el-input v-model="auditForm.audit_remark" autocomplete="off" />
                    </el-form-item>
                  <el-form-item label="结算状态" prop="is_settled">
                    <el-radio-group v-model="auditForm.is_settled">
                      <el-radio label="0">未结算</el-radio>
                      <el-radio label="1">已结算</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
                <div style="display: flex; justify-content: center;margin-top: 20px;">
                    <el-button @click="auditFormVisible = false" style="width: 80px;">取消</el-button>
                    <el-button type="primary" @click="submitAudit" style="width: 80px;">保存</el-button>
                </div>
            </el-dialog>
        </el-tab-pane>
    </el-tabs>
</template>

<script setup>
import {ref, getCurrentInstance, onMounted, watch} from 'vue';
import dayjs from 'dayjs';
import {UploadFilled, CloseBold, Plus} from '@element-plus/icons-vue';
import {ElMessage} from "element-plus";

const { proxy, appContext } = getCurrentInstance();
const form = ref({});
const form2 = ref({});
const tableData = ref([]);
const agentList = ref([]);
const confirmFormVisible = ref(false);
const auditFormVisible = ref(false)
const formLabelWidth = '120px';
let isloading = ref(false);
const confirmForm = ref({
    agent_id: '',
    month: '',
    title: '',
    should_checkout_amount: '',
    detail_sheet: '',
});

const auditForm = ref({
    agent_name: '',
    months: [],
    titles: [],
    should_checkout_amount: '',
    detail_sheet: '',
    audit_status: null,
    audit_remark: '',
    is_settled: '0',
});

const isEdit = ref(false);
const dialogTitle = ref('新增佣金确认');

const checkPage = ref('first')
const loadedTabs = ref(new Set()) // 记录已加载的 Tab 页

let pageinfo = ref({
    perPage: 0, // 每页多少条
    total: 0, // 共计多少
    totalPages: 0, // 共计多少页
    currentPage: 1, // 当前页数
});

const pageChange = (page) => {
    checkPage.value === 'first' ? hasSearch(page) : auditHasSearch(page);
};

const hasSearch = (page) => {
    isloading.value = true;
    let info = { page: page };
    commissionList(info);
};
const auditHasSearch = (page) => {
    isloading.value = true;
    let info = { page: page };
    commissionAuditList(info);
};

const auditTableData = ref([])

// 佣金审核列表
const commissionAuditList = async (info) => {
    try {
        isloading.value = true; // 开始加载
        const response = await proxy.$api.agents.commissionAuditList({
          ...info,
          ...form2.value
        });
        auditTableData.value = response.data.data;
        pageinfo.value = {
            perPage: response.data.pagination.perPage,
            total: response.data.pagination.total,
            totalPages: response.data.pagination.totalPages,
            currentPage: response.data.pagination.currentPage
        };
    } catch (error) {
        proxy.$message.error('获取佣金审核列表失败'); // 请求失败处理
    } finally {
        isloading.value = false;
    }
};

const previewSrcList = ref([])
// 审核
const auditCommission = (row) => {
    auditForm.value = {
        id: row.id,
        audit_status: row.audit_status,
        audit_remark: row.audit_remark,
        is_settled: row.settled_at ? '1' : '0',
        agent_name: row.confirm_records[0].agent_name,
        months: row.confirm_records.map(record => record.month),
        titles: row.confirm_records.map(record => record.title),
        should_checkout_amount: row.should_checkout_amount,
        detail_sheet: row.detail_sheet,
        imgUrl: row.invoice_file_url
    };
    previewSrcList.value = [row.invoice_file_url];
    auditFormVisible.value = true;
};

// 提交审核
const submitAudit = async () => {
    try {
      const {id, audit_status, audit_remark, is_settled} = auditForm.value
        const response = await proxy.$api.agents.commissionAudit({
            id,
            audit_remark,
            audit_status,
            is_settled,
        });
        if (response.status === 200) {
            proxy.$message.success('保存成功');
            auditFormVisible.value = false;
            commissionAuditList(); // 刷新列表
            todoList()
        }
    } catch (error) {
        proxy.$message.error('审核失败，请稍后重试');
        console.error('审核失败:', error);
    }
};

// 佣金确认列表
const commissionList = async (info) => {
    try {
        isloading.value = true; // 开始加载
        const response = await proxy.$api.agents.commissionList({
          ...info,
          ...form.value
        });
        tableData.value = response.data.data;
        pageinfo.value = {
            perPage: response.data.pagination.perPage,
            total: response.data.pagination.total,
            totalPages: response.data.pagination.totalPages,
            currentPage: response.data.pagination.currentPage
        };
    } catch (error) {
        proxy.$message.error('获取佣金列表失败'); // 请求失败处理
    } finally {
        isloading.value = false;
    }
};

// 下载文件
const downloadFile = (url) => {
    window.open(url);
};

// 删除记录
const deleteRecord = async (row) => {
  if (row.is_applied) {
    return proxy.$message.warning('已经提交申请的记录不可以删除');
  }

  // 弹窗确认
  appContext.config.globalProperties.$confirm('该记录将被永久删除，请确认', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    proxy.$api.agents.commissionDelete({ id: row.id }).then(() => {
      commissionList();
      proxy.$message.success('删除成功');
    }).catch(() => {
      ElMessage.info('删除失败')
    })
  })
};

// 获取代理列表
const queryAgentList = async () => {
    const response = await proxy.$api.queryAgentList();
    agentList.value = transformData(response.data.data.tree);
};

// 处理代理数据
const transformData = (nodes) => {
  return nodes.map((node) => ({
    value: node.id,
    label: node.company + ' ('+ node.name + ')',
  }));
};

// 上传文件
const uploadFile = async (options) => {
    const formData = new FormData();
    formData.append('file', options.file);
    const response = await proxy.$api.agents.upLoadFile(formData);
    confirmForm.value.detail_sheet = response.data.data.id;
    confirmForm.value.detail_sheet_url = response.data.data.url;
    proxy.$message.success('文件上传成功');
};

// 清空上传文件
const clearFile = () => {
    confirmForm.value.detail_sheet = '';
    confirmForm.value.detail_sheet_url = '';
};

// 判断是否为图片链接
const isImageUrl = (url) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const extension = url.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
};
queryAgentList();

// 打开新增/编辑对话框
const openDialog = (edit, row = null) => {
    isEdit.value = edit;
    dialogTitle.value = edit ? '编辑佣金确认' : '新增佣金确认';
    if (edit) {
        confirmForm.value = {
            id: row.id,
            agent_id: row.agent_id,
            month: row.month,
            title: row.title,
            should_checkout_amount: row.should_checkout_amount,
            detail_sheet: row.detail_sheet,
            detail_sheet_url: row.detail_sheet_url,
        };
    } else {
        confirmForm.value = {
            agent_id: '',
            month: dayjs().subtract(1, 'month').format('YYYYMM'),
            title: '',
            should_checkout_amount: '',
            detail_sheet: '',
            detail_sheet_url: '',
        };
    }
    confirmFormVisible.value = true;
};

// 关闭对话框并重置表单
const closeDialog = () => {
    confirmFormVisible.value = false;
    formRef.value.resetFields();
};

// 在关闭对话框前重置表单
const handleDialogClose = (done) => {
    formRef.value.resetFields();
    done();
};

// 校验规则
const rules = {
    agent_id: [
        { required: true, message: '请选择一级代理', trigger: 'change' },
    ],
    month: [
        { required: true, message: '请选择分润月份', trigger: 'change' },
    ],
    title: [
        { required: true, message: '请输入佣金项目', trigger: 'blur' },
    ],
    should_checkout_amount: [
        { required: true, message: '请输入应结佣金', trigger: 'blur' },
        {
            validator: (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('应结佣金不能为空'));
                }
                if (!/^-?\d+(\.\d{1,2})?$/.test(value)) {
                    return callback(new Error('应结佣金必须为数字且最多两位小数'));
                }
                callback();
            },
            trigger: 'blur',
        },
    ],
    detail_sheet: [
        { required: true, message: '请上传佣金明细表', trigger: 'change' },
    ],
};

const formRef = ref(null);

// 提交表单
const submitForm = () => {
    formRef.value.validate(async (valid) => {
        if (valid) {
            try {
                if (isEdit.value) {
                    await proxy.$api.agents.commissionEdit(confirmForm.value);
                } else {
                    await proxy.$api.agents.commissionAdd(confirmForm.value);
                }
                commissionList();
                confirmFormVisible.value = false;
                proxy.$message.success('操作成功');
            } catch (error) {
                proxy.$message.error('操作失败');
            }
        }
    });
};

const rowStyle = () => {
    return { height: '60px' };
};

// 获取审核状态类
const getAuditStatusClass = (status) => {
    switch (status) {
        case 0:
            return 'pending-status';
        case 1:
            return 'approved-status';
        case 2:
            return 'rejected-status';
        default:
            return '';
    }
};

const activeName = ref('first')

// 处理 Tab 点击事件
const handleClick = (tab) => {
    todoList();
    checkPage.value = tab.paneName
    if (!loadedTabs.value.has(tab.paneName)) {
        pageChange(1);
        loadedTabs.value.add(tab.paneName); // 标记该 Tab 已加载
    }
}

//待审核数据
const todoCount = ref('0')
const todoList = async () => {
    try {
        const response = await proxy.$api.agents.todoList();
        todoCount.value = response.data.data.to_audit_count
    } catch (error) {
        proxy.$message.error('获取待审核数据失败'); // 请求失败处理
    }
}

// 当todoCount发生变化时，刷新待审核数据
watch(todoCount, () => {
  auditHasSearch(1);
})

//刷新当前卡片
const refreshTab = (tabName) => {
    if (tabName === 'first') {
        todoList();
        hasSearch(1);
    } else if (tabName === 'second') {
        todoList();
        auditHasSearch(1);
    }
}

onMounted(() => {
    commissionList();
    todoList();
    loadedTabs.value.add('first'); // 初始化第一个 Tab
});
</script>

<style scoped>
.headChange {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    gap: 20px;
}

.tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}

.change {
    display: flex;
    align-items: center;
    color: #79bbff;
    cursor: pointer;
}

.change:hover {
    color: #f3d19e;
}

.custom-form .el-form-item {
    width: 360px;
}

.pending-status {
    color: #999999;
}

.approved-status {
    color: #67C23A;
}

.rejected-status {
  color: #e62a33;
}
</style>
